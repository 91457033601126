


























































import { Vue, Component } from "vue-property-decorator";
import { AlertDialogProperties } from "@/components/shared/AlertDialogView.vue";
import { EditorControlProperties } from "@/components/shared/EditorControlView.vue";
import EditorControlView from "@/components/shared/EditorControlView.vue";
import SystemSettingService from "@/services/SystemSettingService";
import ExceptionUtil from "@/utilities/ExceptionUtil";
import EventManager from "@/utilities/EventManager";

const AlertDialogView = () => import("@/components/shared/AlertDialogView.vue");
const SystemSettingAccountView = () => import("./SystemSettingAccountView.vue");
const SystemSettingVoucherView = () => import("./SystemSettingVoucherView.vue");
const SystemSettingLiveSessionView = () => import("./SystemSettingLiveSessionView.vue");
const SystemSettingSessionClosingView = () => import("./SystemSettingSessionClosingView.vue");
const SystemSettingMiscellaneousView = () => import("./SystemSettingMiscellaneousView.vue");

class SystemSettingInfoProperties {
    systemSetting: any = {
        account: { facebookAccount: {} }
    };
    customerTokenExpires: any = null;
    customerTokenExpiresUsed: boolean = false;
    saving: boolean = false;
    events = new EventManager();
}

export { SystemSettingInfoProperties };

@Component({
    components: {
        EditorControlView,
        AlertDialogView,
        SystemSettingAccountView,
        SystemSettingVoucherView,
        SystemSettingLiveSessionView,
		SystemSettingSessionClosingView,
        SystemSettingMiscellaneousView
    },
    data: () => ({
        name: "system-setting",
        tab: null
    })
})
export default class SystemSettingInfoView extends Vue {
    private properties = new SystemSettingInfoProperties();
    private systemSettingService = new SystemSettingService();
    private alertDialog = new AlertDialogProperties();
    private editorControl = new EditorControlProperties();

    public created() {
        this.editorControl.createVisible = false;
        this.editorControl.deleteVisible = false;
        this.load();
    }

    public async load() {
        try {
            const r = await this.systemSettingService.get();
            if (!r.data) {
                throw new Error(this.$t("message.record-not-found").toString());
            }
            const systemSetting = r.data;
            const account = systemSetting.account ??= {};
            const liveSession = systemSetting.liveSession ??= {};

			systemSetting.voucher ??= {};
			systemSetting.sessionClosing ??= {};

			account.facebookAccount ??= {};
			liveSession.standardProductPrices ??= [];
            liveSession.fullNameColor ??= "#000000";
			liveSession.messageColor ??= "#000000";
			liveSession.backgroundColor ??= "#000000";
			liveSession.tagColor ??= "#000000";

			liveSession.standardProductPrices = liveSession.standardProductPrices
				.sort((lhs, rhs) => lhs.slot - rhs.slot);

            const miscellaneous = systemSetting.miscellaneous;
            this.properties.customerTokenExpires = 
                miscellaneous.customerTokenExpires !== null ?
                Number(miscellaneous.customerTokenExpires) / 60 : null;
            this.properties.customerTokenExpiresUsed = 
                this.properties.customerTokenExpires !== null;
            this.properties.systemSetting = systemSetting;
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.tag = { command: "load-error" };
                this.alertDialog.visible = true;
            }
        }
    }

    public async control(button: string) {
        if (button === "cancel") {
            this.cancel();
        } else if (button === "save") {
            await this.save();
        }
    }

    public cancel() {
        if (this.properties.saving) {
            return;
        }

        AlertDialogProperties.discard(this.alertDialog, null, null);
        this.alertDialog.tag = { command: "discard-changes" };
        this.alertDialog.visible = true;
    }

    public async save() {
        if (this.properties.saving) {
            return;
        }

        this.properties.saving = true;
        try {
            var systemSetting = this.properties.systemSetting;
            var miscellaneous = systemSetting.miscellaneous;
            var liveSession = systemSetting.liveSession;

            miscellaneous.customerTokenExpires = 
                this.properties.customerTokenExpiresUsed && this.properties.customerTokenExpires ? 
                this.properties.customerTokenExpires * 60 : null;

            const r = await this.systemSettingService.put(systemSetting);
            systemSetting = r.data;
            miscellaneous = systemSetting.miscellaneous;
            liveSession = systemSetting.liveSession;

			liveSession.standardProductPrices = liveSession.standardProductPrices
				.sort((lhs, rhs) => lhs.slot - rhs.slot);

            this.properties.customerTokenExpires = 
                miscellaneous.customerTokenExpires !== null ?
                Number(miscellaneous.customerTokenExpires) / 60 : null;
            this.properties.customerTokenExpiresUsed = 
                this.properties.customerTokenExpires !== null;
            this.properties.systemSetting = systemSetting;

            await this.properties.events.fire('update-formatter');
            AlertDialogProperties.saved(this.alertDialog, null, null);
            this.alertDialog.visible = true;
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        } finally {
            this.properties.saving = false;
        }
    }

    public async alertDialogClicked(button: string) {
        const tag = this.alertDialog.tag;
        this.alertDialog.visible = false;
        if (tag && tag.command === "discard-changes") {
            if (button === this.$t("text.yes")) {
                await this.load();
            }
        }

        await this.properties.events.fire("alert-dialog-clicked", button);
        await this.alertDialogClosed();
    }

    public async alertDialogClosed() {
        if (this.alertDialog.visible) {
            return;
        }

        const tag = this.alertDialog.tag;
        if (tag) {
            if (tag.command === "load-error") {
                await this.$router.push("/home");
            } else if (tag.command === "refresh-token-successful") {
                await this.$router.replace("/management/settings/system-setting");
            }
        }

        await this.properties.events.fire("alert-dialog-closed");
    }
}
